<template>
  <v-bottom-sheet v-model="internalDialog" inset class="mx-auto" style="width:100%; max-width:400px">

    <v-card class="pa-4 text-center text-wrap" style="border-top-left-radius:20px;
      border-top-right-radius:20px;">
      <v-card-title class="text-center text-wrap">Yon espas kote imajinasyon nou san limit</v-card-title>
      <v-card-text class="text-center text-wrap">Enskri gratis</v-card-text>
      <!-- Bouton -->
      <v-btn @click="signInWithGoogle" size="x-large" block rounded="xl" class="mb-4 mt-4" elevation="4">
        <v-img src="/img/icons/google.svg" alt="google Icon" style="width: 40px; height: 40px; margin-right: 4px;">
        </v-img>
        Kontinye ak Google</v-btn>

      <v-btn @click="signWithEmail = true" variant="elevated" elevation="4" class="mb-4" block
        prepend-icon="mdi-email-outline" rounded="xl" size="x-large" color="warning">
        Imel ak modpas
      </v-btn>
    </v-card>
  </v-bottom-sheet>

  <v-dialog v-model="signWithEmail" persistent fullscreen>
    <v-card style="width:100%;" max-width="344">

      <!-- Close Button -->
      <div class="d-flex justify-end mr-4 mt-4 mb-8">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <!-- Section Choix Konekte ou Enskri -->
      <!-- <div class="d-flex justify-left flex-row mr-4 mt-8 mb-4">
        <h1 class="ml-4 mr-2 text-left" :class="{ 'text-grey': !isLogin }"
          style="font-size:40px; cursor:pointer" @click="switchToLogin">Konekte</h1>
        <h1 class="text-left" :class="{ 'text-grey': isLogin }" style="font-size:40px; cursor:pointer"
          @click="switchToRegister">Enskri</h1>
      </div> -->

      <div class="d-flex justify-left flex-row mr-4 mt-8 mb-4">
        <h1 class="ml-4 mr-2 text-left" :class="{ 'text-grey': !isLogin, 'text-orange': isLogin }"
          style="font-size:40px; cursor:pointer" @click="switchToLogin">Konekte</h1>
        <h1 class="text-left" :class="{ 'text-grey': isLogin, 'text-orange': !isLogin }"
          style="font-size:40px; cursor:pointer" @click="switchToRegister">Enskri</h1>
      </div>


      <!-- Formulaire de Connexion -->
      <v-form v-if="isLogin" @submit.prevent="login">
        <div class="d-flex justify-center flex-column mx-auto p-2" style="width:100%; max-width: 400px;">

          <v-text-field type="email" v-model="email" placeholder="Imel*" prepend-inner-icon="mdi-email-outline"
            density="compact" variant="solo" autocomplete="user-email" clearable :rules="[rules.required]" />

          <v-text-field v-model="password" :type="visible ? 'text' : 'password'" placeholder="Modpas*"
            prepend-inner-icon="mdi-lock-outline" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            density="compact" variant="solo" autocomplete="current-password" @click:append-inner="visible = !visible"
            clearable :rules="[rules.required]" />

          <p v-if="errMsg" class="text-white text-center">{{ errMsg }}</p>

          <v-btn @click="login" type="button" color="white" elevation="4" size="x-large" rounded="xl"
            class="text-none mb-4 text-warning">
            Konekte
          </v-btn>

          <div class="d-flex justify-center" style="cursor:pointer">
            <span class="text-none text-warning text-center" @click="navigateTo('/ForgotPasswordPage')">
              Ou bliye modpas ou? Klike
              la</span>
          </div>


        </div>

        <!-- Politique et Conditions -->
        <v-alert class="text-center" variant="text">
          <div class="d-flex flex-column">
            <span class="text-none font-weight-bold cursor-pointer" @click="navigateTo('/PolitiqueUserPage')">Politik
              Konfidansyalite</span>

            <span class="text-none font-weight-bold cursor-pointer" @click="navigateTo('/ConditionUserPage')">Kondisyon
              itilizasyon</span>

          </div>

        </v-alert>

      </v-form>

      <!-- Formulaire d'Inscription -->
      <v-form v-if="!isLogin" @submit.prevent="register">
        <div class="d-flex justify-center flex-column mx-auto p-2" style="width:100%; max-width: 400px;">

          <v-text-field placeholder="Siyati*" v-model="siyati" density="compact" variant="solo" clearable
            prepend-inner-icon="mdi-account-outline" :rules="[rules.required]" />

          <v-text-field placeholder="Non*" v-model="non" density="compact" variant="solo" clearable
            prepend-inner-icon="mdi-account-outline" :rules="[rules.required]" />

          <v-text-field type="email" placeholder="Imel*" v-model="email" prepend-inner-icon="mdi-email-outline"
            density="compact" variant="solo" clearable :rules="[rules.required]" />

          <v-text-field placeholder="Modpas*" v-model="password" prepend-inner-icon="mdi-lock-outline"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append-inner="visible = !visible"
            density="compact" variant="solo" clearable :rules="[rules.required]" />


          <v-text-field placeholder="Konfime Modpas*" v-model="confirmPassword" prepend-inner-icon="mdi-lock-outline"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append-inner="visible = !visible"
            density="compact" variant="solo" clearable :rules="[rules.required]" />

          <p v-if="registrationMessage" class="registration-message text-danger text-center">{{ registrationMessage }}
          </p>

          <v-btn @click="register" type="submit" color="warning" elevation="4" size="x-large" rounded="xl" block
            class="mx-auto text-none mb-4 text-white">
            Enskri
          </v-btn>
        </div>

        <!-- Politique et Conditions -->
        <v-alert class="text-center" variant="text">Si ou enskri, sa vle di ou aksepte
          <span class="text-none text-grey-darken-3 font-weight-bold" @click="navigateTo('/PolitiqueUserPage')">Politik
            Konfidansyalite</span>
          ak
          <span class="text-none text-grey-darken-3 font-weight-bold"
            @click="navigateTo('/ConditionUserPage')">Kondisyon
            itilizasyon</span>
          aplikasyon an.
        </v-alert>
        <br>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
// import { Plugins } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential
} from "firebase/auth";
import { useRouter } from 'vue-router';
import { defineProps, defineEmits } from 'vue';
import { db } from "@/main"; // Importez `db` depuis le fichier main.js

import {collection, setDoc, doc, serverTimestamp, getDoc, updateDoc, where, getDocs, query } from 'firebase/firestore';

// Props et évenements
const props = defineProps({
  dialog: {
    type: Boolean,
    required: true
  },
   dialog2: {
    type: Boolean,
   required: false, // Changez en 'false' si elle n'est pas toujours nécessaire
    default: false // Ajoutez une valeur par défaut pour éviter l'erreur
  }
});
const emit = defineEmits(['update:dialog']);

// Variables d'état
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const siyati = ref("");
const non = ref("");
const errMsg = ref("");
const registrationMessage = ref("");
const visible = ref(false);
const internalDialog = ref(props.dialog);
const signWithEmail = ref(props.dialog2);
const isLogin = ref(true);
const router = useRouter();

// Règles de validation
const rules = {
  required: value => !!value || 'Chan sa yo pa dwe vid',
  min: (v) => v.length >= 8 || 'Modpas la dwe gen omwen 8 karaktè.',
  emailMatch: () => 'Imel ak modpas ou antre yo pa matche.',
};

// Watchers
watch(() => props.dialog, (val) => {
  internalDialog.value = val;
});
watch(internalDialog, (val) => {
  emit('update:dialog', val);
});

watch(() => props.dialog2, (val) => {
  signWithEmail.value = val;
});
watch(signWithEmail, (val) => {
  emit('update:dialog2', val);
});
// Méthodes
const closeDialog = () => {
  signWithEmail.value = false;
  internalDialog.value = false;
};

// Fonction pour basculer entre Konekte et Enskri
const switchToLogin = () => {
  isLogin.value = true;
};
const switchToRegister = () => {
  isLogin.value = false;
};

// Fonction de connexion avec email et password
// const login = () => {
//   signInWithEmailAndPassword(getAuth(), email.value, password.value)
//     .then(() => {
//       internalDialog.value = false;
//       router.push('/HomePage');
//     })
//     .catch((error) => {
//       switch (error.code) {
//         case "auth/invalid-email":
//           errMsg.value = "Imel ou pa bon/ okenn chan pa ka vid";
//           break;
//         case "auth/user-not-found":
//           errMsg.value = "Nou pa gen kont ak imel sa a.";
//           break;
//         case "auth/wrong-password":
//           errMsg.value = "Modpas la pa bon/ okenn chan pa ka vid";
//           break;
//         default:
//           errMsg.value = "Imel ou oubyen modpas ou pa bon/ okenn chan pa ka vid";
//           break;
//       }
//     });
// };


const login = async () => {
  try {
    // Connexion à Firebase Auth
    const auth = getAuth();
    const result = await signInWithEmailAndPassword(auth, email.value, password.value);
    const user = result.user;

    // console.log('Utilisateur connecté:', user);

    // Vérification ou création du document utilisateur
    const userDocRef = doc(collection(db, "users"), user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      await updateDoc(userDocRef, {
        lastLogin: serverTimestamp(),
        isOnline: true,
      });
    }
    // Réinitialiser les valeurs de email et password
    email.value = '';
    password.value = '';
    // Fermer le dialogue interne et rediriger
    // internalDialog.value = false;
    signWithEmail.value = false;
    internalDialog.value = false;
    router.push('/HomePage');
  } catch (error) {
    // Gestion des erreurs
    switch (error.code) {
      case "auth/invalid-email":
        errMsg.value = "Imel ou pa bon/ okenn chan pa ka vid";
        break;
      case "auth/user-not-found":
        errMsg.value = "Nou pa gen kont ak imel sa a.";
        break;
      case "auth/wrong-password":
        errMsg.value = "Modpas la pa bon/ okenn chan pa ka vid";
        break;
      default:
        errMsg.value = "Imel ou oubyen modpas ou pa bon/ okenn chan pa ka vid";
        break;
    }
  }
};
const getNonFromEmail = (email) => {
  const prefix = email.split("@")[0];
  const parts = prefix.split(".");

  if (parts.length > 1) {
    return {
      firstName: parts[0],
      lastName: parts[1]
    };
  } else {
    return {
      firstName: prefix,
      lastName: `User${Math.floor(Math.random() * 10)}${String.fromCharCode(65 + Math.floor(Math.random() * 26))}${String.fromCharCode(65 + Math.floor(Math.random() * 26))}${Math.floor(Math.random() * 10)}`,
    };
  }
};

// Fonction pour sauvegarder les données utilisateur dans Firestore
const saveUserToFirestore = async (user) => {
  const userDocRef = doc(collection(db, "users"), user.uid);

  // Récupérer le document utilisateur
  const userDoc = await getDoc(userDocRef);

  // Si l'utilisateur n'existe pas, on le crée
  if (!userDoc.exists()) {
    const { firstName, lastName } = getNonFromEmail(user.email);

    // Créer un nouveau document avec les données de l'utilisateur
    await setDoc(userDocRef, {
      userId: user.uid,
      imel: user.email,
      created: serverTimestamp(),
      dateInscription: serverTimestamp(),
      lastLogin: serverTimestamp(),
      non: firstName,
      siyati: lastName,
      photoURL: user.photoURL || '',
      subscribedToAnnLi: false,
      notYet: true,
      translator: false,
      writer: false,
      admin: false,
      demandeResiliation: false,
      resiliationReasonWhy: "",
      credit: 5,
      allValidCorrections: 0,
      forLevelUser: 0,
      subscribedToWriters: [],
      usersSubscribedToMe: [],
      newUser: true,
      demandeSubscription: false,
      understoodValidation: false,
      understoodRead: false,
      understoodClassification: false,
      firstWinner: false,
      secondWinner: false,
      thirdWinner: false,
      nonValid: false,
      siyatiValid: false,
      imelValid: false,
      updateApp: true,
      authorizedCamera: false,
      prime: false,
      fb: '',
      tk: '',
      x: '',
      yt: '',
      ig: '',
      shareEmailToReaders: 'non',
      isOnline: true,
      notificationAccepted: false,
      country: '',
      age: '',
      cible: '+18',
      shareLibrary: false,
      socialMedia: false,
    });
  } else {
    // Si l'utilisateur existe déjà, on met à jour seulement les champs nécessaires
    await updateDoc(userDocRef, {
      lastLogin: serverTimestamp(),
      isOnline: true,
    });
  }
};

const signInWithGoogle = async () => {
  const auth = getAuth();

  try {
    let user;
    if (Capacitor.isNativePlatform()) {
      const googleUser = await GoogleAuth.signIn();
      const idToken = googleUser.authentication.idToken;
      const credential = GoogleAuthProvider.credential(idToken);
      const result = await signInWithCredential(auth, credential);
      user = result.user;
    } else {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      user = result.user;
    }

    await saveUserToFirestore(user);
    // signWithEmail.value = false;
    internalDialog.value = false;
    signWithEmail.value = false;

    router.push({ path: '/HomePage' });
    internalDialog.value = false;
    signWithEmail.value = false;

  } catch (error) {
    // console.error("Erreur lors de l'authentification Google :", error);
    // alert(error.message || "Une erreur s'est produite lors de la connexion.");
  }
};


// Fonction d'inscription avec email et password
const register = async () => {
  // Vérification des règles de mot de passe
  if (!rules.required(password.value)) {
    registrationMessage.value = rules.required(password.value);
    return;
  }
  if (!rules.min(password.value)) {
    registrationMessage.value = rules.min(password.value);
    return;
  }

  if (password.value !== confirmPassword.value) {
    registrationMessage.value = "Modpas yo pa menm";
    return;
  }

  try {

    // Vérification si l'e-mail est déjà utilisé
    const userCollectionRef = collection(db, 'users');
    const emailQuery = query(userCollectionRef, where("imel", "==", email.value));
    const emailSnapshot = await getDocs(emailQuery);

    if (!emailSnapshot.empty) {
      registrationMessage.value = "Imel sa a deja egziste nan sistèm nan.";
      return;
    }

    // Si l'e-mail n'existe pas, créer l'utilisateur
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
    const user = userCredential.user;

    // const db = getFirestore();
    await setDoc(doc(collection(db, 'users'), user.uid), {
      userId: user.uid,
      imel: email.value,
      created: serverTimestamp(),
      dateInscription: serverTimestamp(),
      lastLogin: serverTimestamp(),
      non: non.value,
      siyati: siyati.value,
      photoURL: '', // Ajout de la photo
      subscribedToAnnLi: false,
      notYet: true,
      translator: false,
      writer: false,
      admin: false,
      demandeResiliation: false,
      resiliationReasonWhy: false,
      credit: 5,
      allValidCorrections: 0,
      forLevelUser: 0,
      subscribedToWriters: [],
      usersSubscribedToMe: [],
      newUser: true,
      demandeSubscription: false,
      understoodValidation: false,
      understoodRead: false,
      understoodClassification: false,
      firstWinner: false,
      secondWinner: false,
      thirdWinner: false,
      nonValid: false,
      siyatiValid: false,
      imelValid: false,
      updateApp: true,
      authorizedCamera: false,
      prime: false,
      fb:'',
      tk: '',
      x: '',
      yt: '',
      ig: '',
      shareEmailToReaders: 'non',
      isOnline: true,
      notificationAccepted: false,
      country: '',
      age: '',
      cible: '+18',
      shareLibrary: false,
      socialMedia: false,
    });
    // Réinitialisation du formulaire et fermeture du dialogue
    registrationMessage.value = "";
    signWithEmail.value = false;
    internalDialog.value = false;
    router.push('/HomePage');
    signWithEmail.value = false;
    internalDialog.value = false;
  } catch (error) {
    switch (error.code) {
      case "auth/invalid-email":
        registrationMessage.value = "Imel ou pa bon/ okenn chan pa ka vid";
        break;
      case "auth/email-already-in-use":
        registrationMessage.value = "Imel sa a deja gen kont ki kreye.";
        break;
      case "auth/weak-password":
        registrationMessage.value = "Modpas la twò fèb.";
        break;
      default:
        registrationMessage.value = "Yon erè rive pandan enskripsyon an.";
        break;
    }
  }
};
// Navigation vers d'autres pages
const navigateTo = (route) => {
  signWithEmail.value = false;
  internalDialog.value = false; // Ferme le v-dialog
  router.push(route);
};
</script>

<style scoped>
.text-warning {
  margin-bottom: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  text-decoration: none;
}

.text-grey {
  color: grey;
}

.btn-warning {
  background-color: yellow;
  color: black;
}

.btn-grey {
  background-color: grey;
  color: white;
}
</style>
