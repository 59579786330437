import { createRouter, createWebHistory } from 'vue-router';
import { SplashScreen } from '@capacitor/splash-screen';
// import { StatusBar } from '@capacitor/status-bar';
// import { Capacitor } from '@capacitor/core';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import Cookies from 'js-cookie';
import store from './store';

// Créer le router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    // LES ROUTES DISPONIBLES QUAND NON CONNECTER
 
    {
      path: "/", component: () => import("./pages/UnConnected-Page.vue")
    },

    { path: "/RegisterPage", component: () => import("./pages/Register-Page.vue") },
    
    {
      path: "/ForgotPasswordPage", component: () => import("./pages/Forgot-Password-Page.vue"),
   meta: {
     requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
     
      },

    },

    //LES ROUTES DISPONIBLES QUAND CONNECTER

    { path: "/HomePage", name: 'homePage', component: () => import("./pages/Home-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },
     
      { path: "/BookPropositionPage/:id", name: 'proposition-page', component: () => import("./pages/Book-Proposition-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
    { path: "/AdminPage", name: 'adminPage', component: () => import("./pages/Admin-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        
      { path: "/PolitiqueConfidentialitePage", name: 'politique-page', component: () => import("./pages/Politique-Confidentialite-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
        }, },

  { path: "/PolitiqueUserPage", name: 'politique-user-page', component: () => import("./pages/Politique-User-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
   
  { path: "/BecomeWriter", name: 'become-writer-page', component: () => import("./pages/Become-Writer-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    {
      path: "/TranslationProposition", name: 'translation-proposition-page',
      component: () => import("./pages/Translation-Proposition-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    {
      path: "/CopyRight", name: 'copyright',
      component: () => import("./pages/Copy-Right.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        
    { path: "/PolitiqueUserConnectPage", name: 'politique-user-connect-page', component: () => import("./pages/Politique-User-Connect-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
        }, },
        
          { path: "/ConditionUtilisationPage", name: 'condition-page', component: () => import("./pages/Condition-Utilisation-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
        }, },

         { path: "/ConditionUserPage", name: 'condition-user-page', component: () => import("./pages/Condition-User-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        
    { path: "/ConditionUserConnectPage", name: 'condition-user-connect-page', component: () => import("./pages/Condition-User-Connect-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

   { path: "/GoToRules", name: 'dous-litere-rules', component: () => import("./pages/Dous-Litere-Rules.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        
     { path: "/WhoAreWePage", name: 'who-are-we-page', component: () => import("./pages/Who-Are-We-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

     { path: "/WhoAreWeConnectPage", name: 'who-are-we-connect-page', component: () => import("./pages/Who-Are-We-Connect-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

      { path: "/WhoAreWeUnconnectPage", name: 'who-are-we-unconnect-page', component: () => import("./pages/Who-Are-We-Unconnect-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        
    
    { path: "/SearchPage", component: () => import("./pages/Search-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },


     { path: "/UserSearchPage", name: 'user-search-page', component: () => import("./pages/User-Search-Page.vue"),
    meta: {
          requiresAuth: true,
      },  
    },


    { path: "/LibraryPage", name: "library-page", component: () => import("./pages/Library-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
       
        { path: "/LibraryPageOffline", component: () => import("./pages/Library-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },

      { path: "/ContactPage", name: 'contact-page', component: () => import("./pages/Contact-Page.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
        }, },

    { path: "/PublishPage", name: 'publishPage', component: () => import("./pages/Publish-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

     { path: "/PublishPageOffline", name: 'publishPageOffline', component: () => import("./pages/Publish-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },


         { path: "/NotificationsPage", name:'notifications-page', component: () => import("./pages/Notifications-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

          { path: "/NotificationsDraftPage/:id", name:'notifications-draft-page', component: () => import("./pages/Notifications-Draft-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

              { path: "/MessagesSendPage/:id", name:'messages-send-page', component: () => import("./pages/Messages-Send-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

     { path: "/MessagesReceivedPage/", name:'messages-received-page', component: () => import("./pages/Messages-Received-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
    
    {
      path: "/NotificationsPageOffline", name: 'notifications-page-offline',
      component: () => import("./pages/Notifications-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },

       { path: "/ProfilPage", name: 'profil-page', component: () => import("./pages/Profil-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

        { path: "/ListAbonnementPage/:id", name: 'list-abonnement-page', component: () => import("./pages/List-Abonnement-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    
        { path: "/ListAbonnePage/:id", name: 'list-abonne-page', component: () => import("./pages/List-Abonne-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

        { path: "/ListAbonnementUserPage/:id", name: 'list-abonnement-user-page', component: () => import("./pages/List-Abonnement-User-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

       { path: "/ListAbonneUserPage/:id", name: 'list-abonne-user-page', component: () => import("./pages/List-Abonne-User-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

      { path: "/ModifyInfoUsers", name: 'modify-info-users', component: () => import("./components/connected-components/Modify-Info-Users.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

      { path: "/RatingAppPage", name: 'rating-app-page', component: () => import("./pages/Rating-App-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

       { path: "/ParametresPage", component: () => import("./pages/Parametres-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },
        { path: "/FormNewPublishStoryPage", component: () => import("./pages/Form-New-Publish-Story-Page.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    { path: "/FormChaptersPublishBis/:id", name: 'form-chapters-publish-bis', component: () => import("./components/connected-components/Form-Chapters-Publish-Bis.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/ModifyBookInfoGeneralBis/:id", name: 'modify-book-info-general-bis', component: () => import("./components/connected-components/Modify-Book-Info-General-Bis.vue"),
    meta: {
          requiresAuth: true,
      },
    },

       { path: "/ModifyPages/:id", name: 'modify-pages', component: () => import("./components/connected-components/Modify-Pages.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },


     { path: "/ModifyPagesUsers/:id", name: 'modify-pages-users', component: () => import("./components/connected-components/Modify-Pages-Users.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     { path: "/DeletePages/:id", name: 'delete-pages', component: () => import("./components/connected-components/Delete-Pages.vue"),
    meta: {
      requiresAuth: true,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },


    
     { path: "/DeletePagesUsers/:id", name: 'delete-pages-users', component: () => import("./components/connected-components/Delete-Pages-Users.vue"),
    meta: {
          requiresAuth: true,
      },
    },



      { path: "/ModifyEachPage/:id/:chapterId", name: 'modify-each-page', component: () => import("./components/connected-components/Modify-Each-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/PreviewBeforeModify/:id/:chapterId", name: 'preview-before-modify', component: () => import("./components/connected-components/Preview-Before-Modify.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    {
      path: "/ModifyEachPageWriters/:id/:chapterId", name: 'modify-each-page-writers',
      component: () => import("./components/connected-components/Modify-Each-Page-Writers.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     {
      path: "/DeleteEachField/:id/:chapterId", name: 'delete-each-field',
      component: () => import("./components/connected-components/Delete-Each-Field.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/SendNotifications/:id", name: 'send-notifications', component: () => import("./components/connected-components/Send-Notifications.vue"),
    meta: {
          requiresAuth: true,
      },
    },

       { path: "/ModifyNotifications/:id", name: 'modify-notifications', component: () => import("./components/connected-components/Modify-Notifications.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     { path: "/ParametersBook/:id", name: 'parameters-book', component: () => import("./components/connected-components/Parameters-Book.vue"),
    meta: {
      requiresAuth: true,
      
      },
    },


 {
   path: '/notificationDisplay', name: 'notification-display',
   component: () => import("./components/connected-components/Notification-Display.vue"),
    meta: {
          requiresAuth: true,
      },
    },
      {
      path: '/CommentDetails/:id', name: 'comment-details', component: () => import("./components/connected-components/Comment-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },


    
      {
      path: '/CommentPage/:id', name: 'comment-page', component: () => import("./components/connected-components/Comment-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     
      {
        path: '/bookDetails/:id', name: 'book-details',  props: true, 
        component: () => import("./components/connected-components/Book-Details.vue"),
    meta: {
      requiresAuth: false,
      
      },
    },

 {
        path: '/bookDetailsShare/:id', name: 'book-details-share',  props: true, 
        component: () => import("./components/connected-components/Book-Details-Share.vue"),
    meta: {
          requiresAuth: false,
      },
    },
    
      {
        path: '/thankForReading/:id', name: 'thank-for-reading',  props: true, 
        component: () => import("./components/connected-components/Thank-For-Reading.vue"),
    meta: {
          requiresAuth: false,
      },
    },


      {

     path: '/bookContent/:id', name: 'book-content',  props: true, 
        component: () => import("./components/connected-components/Book-Content.vue"),
    meta: {
          requiresAuth: false,
      },
    },

       {

     path: '/bookAlert/:id', name: 'book-alert',  props: true, 
        component: () => import("./components/connected-components/Book-Alert.vue"),
    meta: {
          requiresAuth: false,
      },
    },

      { path: "/AcceptRules", name: 'accept-rules', component: () => import("./components/connected-components/Accept-Dous-Litere-Rules.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    
      { path: "/HowToWrite", name: 'how-to-write', component: () => import("./components/connected-components/How-To-Write.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

        {
        path: '/bookDetailsFromParametersBook/:id', name: 'book-details-from-parameters-book',  props: true, 
        component: () => import("./components/connected-components/Book-Details-From-Parameters-Book.vue"),
    meta: {
          requiresAuth: true,
      },
    },

 
        {

       path: '/userDetails/:id', name: 'user-details',  props: true, 
        component: () => import("./components/connected-components/User-Details.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    {

       path: '/userDetailsForShare/:id', name: 'user-details-for-share',  props: true, 
        component: () => import("./components/connected-components/User-Details-For-Share.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

    
        {

       path: '/WriterDetails/:id', name: 'writer-details',  props: true, 
        component: () => import("./components/connected-components/Writer-Details.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

        {

       path: '/EachUserDetails/:id', name: 'each-user-details',  props: true, 
        component: () => import("./components/connected-components/Each-User-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },
  
    //    {
    //      path: '/bookChapters/:id', name: 'book-chapters',
    //      component: () => import("./components/connected-components/Book-Chapters.vue"),
    //         beforeEnter: async (to, from, next) => {
    //     if (Capacitor.getPlatform() !== 'web') {
    //       await StatusBar.hide();
    //     }
    //     next();
    //   },
    //   beforeLeave: async (to, from, next) => {
    //     if (Capacitor.getPlatform() !== 'web') {
    //       await StatusBar.show();
    //     }
    //     next();
    //   },
    // meta: {
    //       requiresAuth: false,
    //   },
    // },

         {
         path: '/bookChapters/:id', name: 'book-chapters',
         component: () => import("./components/connected-components/Book-Chapters.vue"),
           
    meta: {
      requiresAuth: false,
      transition: 'slide-left' 
           },
    },

      {
      path: '/bookChaptersShare/:id', name: 'book-chapters-share', component: () => import("./components/connected-components/Book-Chapters-Share.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

        {
      path: '/bookChaptersSpecific/:id/:chapterId', name: 'book-chapters-specific', component: () => import("./components/connected-components/Book-Chapters-Specific.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

       {
      path: '/bookChaptersSpecificShare/:id/:chapterId', name: 'book-chapters-specific-share', component: () => import("./components/connected-components/Book-Chapters-Specific-Share.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

     {
      path: '/bookChaptersFromNotif/:id/:chapterId', name: 'book-chapters-from-notif', component: () => import("./components/connected-components/Book-Chapters-From-Notif.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },

       {
      path: '/bookChaptersDownloaded/:id', name: 'book-chapters-downloaded', component: () => import("./components/connected-components/Book-Chapters-Downloaded.vue"),
    meta: {
      requiresAuth: false,
     transition: 'slide-left' , // Transition gauche à droite 
      
      },
    },  

  ],
});

// const getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//     const removeListener = onAuthStateChanged(
//       getAuth(),
//       (user) => {
//         removeListener();
//         // Stocker l'utilisateur dans un cookie avec une expiration
//         Cookies.set('user', JSON.stringify(user), { expires: 90 }); // Exemple : expire après 90 jours
//         resolve(user);
//         // console.log(Cookies);
//       },
//       reject
//     );
//   });
// }

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

// router.beforeEach(async (to, from, next) => {
//   const user = await getCurrentUser();

//   if (to.path === "/" && user) {
//     next("/HomePage");
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (user) {
//       next();
//     } else {
//       alert("You don't have access!");
//       next("/");
//     }
//   } else {
//     next();
//   }
// });



// router.beforeEach(async (to, from, next) => {
//    // Utiliser la fonction getCurrentUser pour obtenir l'utilisateur
//    const user = await getCurrentUser();
//   // const storedUser = Cookies.get('user');
//   // const user = storedUser ? JSON.parse(storedUser) : null;

//   if (to.path === "/" && user) {
//     // Si l'utilisateur est connecté, rediriger vers la page ConnectedPage
//     next("/HomePage");
//     // console.log("okk");
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (user) {
//       // L'utilisateur est connecté, autoriser l'accès
//       next();
//     } else {
//       alert("Ou pa gen aksè! Enskri oubyen konekte pou w gen aksè ak tout kontni sa yo");
//       next("/"); // Rediriger vers la page d'accueil si l'utilisateur n'est pas connecté
//     }
//   } else {
//     next(); // Laisser passer les routes qui ne nécessitent pas d'authentification
//   }
// });


router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser();

  if (to.path === "/" && user) {
    next("/HomePage");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user) {
      next();
    } else {
      store.commit('setShowDialog', true);
      next("/");
    }
  } else {
    next();
  }
});

// router.beforeEach(async (to, from, next) => {
//   const user = await getCurrentUser();
//  if (to.name !== 'book-chapters' && Capacitor.getPlatform() !== 'web') {
//     await FullScreen.setFullScreen(false); // Désactiver le mode plein écran sur les autres pages
//   }
//   if (to.path === "/" && user) {
//     next("/HomePage");
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (user) {
//       next();
//     } else {
//       store.commit('setShowDialog', true);
//       next("/");
//     }
//   } else {
//     next();
//   }
// });

router.afterEach(() => {
  // Cache le Splash Screen après avoir chargé la première route
  setTimeout(() => {
    SplashScreen.hide();
  }, 3000);
});

export default router;
