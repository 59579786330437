// Importation des modules nécessaires
import { createApp } from 'vue';
import App from './App.vue';
import { IonicVue } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
// import { StatusBar, Style } from '@capacitor/status-bar';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// Styles et icônes
import '@mdi/font/css/materialdesignicons.css';
import '@ionic/core/css/ionic.bundle.css';
import '@ionic/vue/css/core.css';
import 'vuetify/styles';

// Importation des librairies de gestion de l'état et de la navigation
import store from './store';
import router from "./router";

// Service worker et gestion des notifications
import './registerServiceWorker';
import "./messagingConfig";
import { requestPermission } from "./messagingConfig";

// Firebase
import { initializeApp } from "firebase/app";
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager, setLogLevel } from "firebase/firestore";
import firebaseConfig from "../firebaseConfig";

// Google Analytics
import VueGtag from 'vue-gtag-next';

// Vuetify
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Initialisation de Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Réduction du niveau de log Firestore
setLogLevel("error"); // Réduit les logs Firestore aux erreurs critiques
// Initialisation de Firestore avec cache hors ligne
const db = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

export { db };
  
//////
 import { defineComponent, onMounted } from 'vue';
import { App as CapacitorApp } from '@capacitor/app';

export default defineComponent({
    name: 'App',
    setup() {
        onMounted(() => {
            // Écouter les événements de deep link
            CapacitorApp.addListener('appUrlOpen', (event) => {
                // Vérifiez si l'URL contient un livre spécifique
                const url = new URL(event.url);
                const bookId = url.pathname.split('/')[1]; // Supposons que l'URL soit /bookDetails/{id}

                // Si un ID est trouvé, redirigez l'utilisateur vers la page du livre
                if (bookId) {
                    // Utilisez Vue Router pour naviguer vers la page de détails
                    router.push(`/bookDetails/${bookId}`);
                }
            });
        });
    },
});

// Initialisation de Vuetify
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          background: '#FFFFFF',
        },
      },
    },
  },
   display: {
    mobileBreakpoint: 'sm', // Définit jusqu'à quelle taille l'écran est considéré comme mobile
  },
});

// Initialisation de l'application Vue
const vueApp = createApp(App);

// Ajout des plugins à l'application Vue
vueApp
  .use(router) // Gestion de la navigation
  .use(store) // Gestion de l'état global
  .use(vuetify) // Framework UI (Vuetify)
  .use(IonicVue) // Intégration d'Ionic
  .use(VueGtag, { property: { id: 'G-MTH7VM2WNG' } }); // Google Analytics

// Configuration spécifique pour les plateformes natives
// const configureStatusBar = () => {
//   try {
//     StatusBar.setBackgroundColor({ color: '#FFFFFF' });
//     StatusBar.setStyle({ style: Style.Light });
//     StatusBar.setOverlaysWebView({ overlay: true });
//   } catch (error) {
//     // console.warn("Erreur lors de la configuration de la barre d'état :", error);
//   }
// };


// const setStatusBarStyleDark = async () => {
//   await StatusBar.setStyle({ style: Style.Dark });
// };

// const setStatusBarStyleLight = async () => {
//   await StatusBar.setStyle({ style: Style.Light });
// };

// // const hideStatusBar = async () => {
// //   await StatusBar.hide();
// // };

// const showStatusBar = async () => {
//   await StatusBar.show();
// };
// const hideStatusBar = () => {
//   try {
//     StatusBar.hide(); // Masque la barre d'état.
//   } catch (error) {
//     console.warn("Erreur lors de la désactivation de la barre d'état :", error);
//   }
// };

const initializeGoogleAuth = async () => {
  try {
    await GoogleAuth.initialize({
      clientId: '514396492015-23ojjkf98574b1unv3dtclag01pc1m35.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
    // console.log("GoogleAuth initialisé avec succès !");
  } catch (error) {
    // console.error("Erreur lors de l'initialisation de GoogleAuth :", error);
  }
};

(async () => {
  if (Capacitor.isNativePlatform()) {
    // configureStatusBar();
    // Display content under transparent status bar (Android only)
// StatusBar.setOverlaysWebView({ overlay: false });
// StatusBar.setBackgroundColor({ color: '#FFFFFF' });
//    await setStatusBarStyleLight();
//     await showStatusBar();
    // await hideStatusBar();
    setTimeout(() => {
  SplashScreen.hide(); // Cache le Splash Screen après 3 secondes
    }, 3000);
    
    await initializeGoogleAuth();
  } else {
    // console.log("Plateforme non native, initialisation GoogleAuth ignorée.");
  }
})();

// Enregistrement du service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker enregistré avec succès:', registration);
    })
    .catch((error) => {
      console.error('Échec de l\'enregistrement du Service Worker:', error);
    });
}

// Autorisation des notifications
requestPermission();

// Montage de l'application Vue
vueApp.mount('#app');

