<template>
    <v-app>
        <router-view v-slot="{ Component }">
            <transition :name="transitionName">
        <component :is="Component" />
      </transition>
        </router-view>
  
        <DialogWarning :dialog="showDialog" @update:dialog="setShowDialog"/>

        <v-snackbar v-model="showSnackbar" :timeout="10000" class="mb-16">
            {{ snackbarMessage }}
        </v-snackbar>
    </v-app>
</template>

<script>
import { onMounted, onBeforeUnmount } from 'vue';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogWarning from './components/connected-components/Dialog-Warning.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue'; // Import de 'computed'
import { PrivacyScreen } from '@capacitor-community/privacy-screen';

export default {
    components: {
        DialogWarning,
    },
    data() {
        return {
            isOnline: navigator.onLine, // Vérifie si l'utilisateur est initialement en ligne
            showSnackbar: false,
            snackbarMessage: '',
        };
    },
    computed: {
       
        ...mapState(['showDialog']),
    },
    methods: {
        ...mapActions(['loadUserPhoto']), // Action pour charger la photo utilisateur
        ...mapMutations(['setShowDialog']),
        handleOnline() {
            this.isOnline = true;
            this.snackbarMessage = 'Ou anliy!';
            this.showSnackbar = true;
        },
        handleOffline() {
            this.isOnline = false;
            this.snackbarMessage =
                'Koneksyon entènèt ou tonbe. Ou ap fonksyone san entènet. Sa rann aplikasyon an lan, men ou kapab kontinye fè lekti menm san entènet. Sepandan, imaj yo ak foto w p ap afiche.';
            this.showSnackbar = true;
        },
        async configureStatusBar() {
            if (Capacitor.isNativePlatform()) {
                // Configuration de la status bar uniquement sur les plateformes natives
                await StatusBar.setOverlaysWebView({ overlay: false });
                await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                await StatusBar.setStyle({ style: 'LIGHT' });
                await StatusBar.show();
                await ScreenOrientation.lock({ orientation: 'portrait' });
            }
        },

        async configurateScreeProtection() {
            if (Capacitor.isNativePlatform()) {
                     // Activer la protection d'écran
    // PrivacyScreen.enable()
            PrivacyScreen.disable()
    //   .then(() => console.log("Privacy screen enabled"))
      .catch((err) => 
      console.error("Error enabling privacy screen", err)
       );
            }
        }
    },
    setup() {
        // Utilisation de `onMounted` pour configurer la StatusBar au montage
        onMounted( () => {
            // await StatusBar.setOverlaysWebView({ overlay: false });
            // await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
            // await StatusBar.setStyle({ style: 'LIGHT' });
            // await StatusBar.show();
            if (Capacitor.isNativePlatform()) {
                setTimeout(async () => {
                    await StatusBar.setOverlaysWebView({ overlay: false });
                    await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                    await StatusBar.setStyle({ style: 'LIGHT' });
                    await ScreenOrientation.lock({ orientation: 'portrait' });
                }, 100); // Délai pour s'assurer que le DOM est prêt
            }
        });

        // Optionnel : Ajout d'un hook avant démontage
        onBeforeUnmount(async () => {
            if (Capacitor.isNativePlatform()) {
                await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
                await StatusBar.setStyle({ style: 'LIGHT' });
                await ScreenOrientation.lock({ orientation: 'portrait' });
            }
        });

    const route = useRoute();

      // Définir dynamiquement le nom de la transition
    const transitionName = computed(() => route.meta.transition || 'default');

    return { transitionName };
  
    },
    async mounted() {
        await this.loadUserPhoto(); // Charger la photo utilisateur dès que l'application est montée
        // Écoute les événements réseau
        window.addEventListener('online', this.handleOnline);
        window.addEventListener('offline', this.handleOffline);

        // Configuration de la StatusBar (redondant, mais parfois utile pour assurer la stabilité)
        await this.configureStatusBar();

         await this.configurateScreeProtection();

    },
    beforeUnmount() {
        // Nettoie les écouteurs pour éviter les fuites mémoire
        window.removeEventListener('online', this.handleOnline);
        window.removeEventListener('offline', this.handleOffline);
    },

//      beforeDestroy() {
//     // Désactiver la protection d'écran
//     PrivacyScreen.disable()
//       .then(() => console.log("Privacy screen disabled"))
//       .catch((err) => console.error("Error disabling privacy screen", err));
//   }
};
</script>

<style>

/* Transition gauche à droite */
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s;
}
.slide-left-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Transition fade */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

</style>

