// import { initializeApp } from "firebase/app";
// import firebaseConfig from "../firebaseConfig";
// import { getAuth, onAuthStateChanged } from "firebase/auth"; // pour obtenir le userId de l'utilisateur connecté
// import { db } from "@/main"; // Importez `db` depuis le fichier main.js
// import { doc, setDoc, updateDoc, deleteField } from "firebase/firestore";
// import { getMessaging, getToken, deleteToken } from "firebase/messaging"; // ajout de deleteToken
// import { Capacitor } from "@capacitor/core";
// import { PushNotifications } from "@capacitor/push-notifications";

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// // Fonction pour demander l'autorisation et récupérer le token
// export const requestPermission = async () => {
//     try {
//         // Vérifiez si les notifications sont supportées
//         if (!("Notification" in window)) {
//             console.error("Ce navigateur ne supporte pas les notifications.");
//             return;
//         }

//         // Demandez la permission
//         const permission = await Notification.requestPermission();

//         if (permission === "granted") {
//             const currentToken = await getToken(messaging, {
//                 vapidKey: "BEgXoJzxbYJD9BPs9rGOcMw4520RU3jnf80hqVvlsCx1l-kWFyVbVA78hJxYnieDLN6p3p2HnuxCoFtekZVXTMQ",
//             });

//             if (currentToken) {
//                 console.log("Token reçu :", currentToken);

//                 const auth = getAuth();
//                 const user = auth.currentUser;

//                 if (user) {
//                     const userId = user.uid;

//                     // Stocker le token dans Firestore
//                     await setDoc(
//                         doc(db, "users", userId),
//                         {
//                             fcmToken: currentToken, isOnline: true,
//       notificationAccepted: true,
//                         },
//                         { merge: true }
//                     );
//                 } else {
//                     console.log("Aucun utilisateur connecté.");
//                 }
//             } else {
//                 console.log("Pas de token disponible.");
//             }
//         } else if (permission === "denied") {
//             console.error("L'utilisateur a refusé les notifications.");
//         } else {
//             console.error("Les notifications sont bloquées.");
//         }
//     } catch (error) {
//         console.error("Erreur lors de la récupération du token :", error);
//     }
// };

// // Fonction pour supprimer le token à la déconnexion
// export const removeTokenOnLogout = async () => {
//     try {
//         const auth = getAuth();
//         const user = auth.currentUser;

//         if (user) {
//             const userId = user.uid;

//             // Supprimer le token dans Firestore
//             await updateDoc(doc(db, "users", userId), {
//                 fcmToken: deleteField(),
//                 isOnline: false,
//             });

//             // Supprimer le token localement
//             await deleteToken(messaging);
//             console.log("Token supprimé avec succès.");
//         }
//     } catch (error) {
//         console.error("Erreur lors de la suppression du token :", error);
//     }
// };

// // Vérification des tokens obsolètes
// export const validateTokens = async () => {
//     const auth = getAuth();

//     onAuthStateChanged(auth, async (user) => {
//         if (user) {
//             const userId = user.uid;
//             const token = await getToken(messaging);

//             // Vérifier si le token est valide
//             if (!token) {
//                 console.warn(`Le token pour l'utilisateur ${userId} est invalide ou expiré.`);
//                 await updateDoc(doc(db, "users", userId), {
//                     fcmToken: deleteField(),
//                 });
//             }
//         }
//     });
// };

// // Gestion des notifications pour les plateformes mobiles (Capacitor)
// if (Capacitor.getPlatform() !== "web") {
//     PushNotifications.addListener("registration", (token) => {
//         console.log("Token d'inscription :", token.value);
//     });

//     PushNotifications.addListener("registrationError", (error) => {
//         console.error("Erreur lors de l'inscription :", error);
//     });

//     PushNotifications.addListener("pushNotificationReceived", (notification) => {
//         console.log("Notification reçue :", notification);
//     });

//     PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
//         console.log("Action de notification effectuée :", action);
//     });
// }

// export { messaging };
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Pour obtenir le userId de l'utilisateur connecté
import { db } from "@/main"; // Importez `db` depuis le fichier main.js
import { doc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import { getMessaging, getToken, deleteToken } from "firebase/messaging"; // Ajout de deleteToken
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Fonction pour demander l'autorisation et récupérer le token
export const requestPermission = async () => {
    try {
        if (Capacitor.isNativePlatform()) {
            // Gestion des notifications pour mobile
            const permStatus = await PushNotifications.requestPermissions();

            if (permStatus.receive === "granted") {
                // console.log("Permission de notification accordée.");

                // Enregistrez le service de notification
                await PushNotifications.register();

                PushNotifications.addListener("registration", async (token) => {
                    // console.log("Token d'inscription reçu :", token.value);

                    const auth = getAuth();
                    const user = auth.currentUser;

                    if (user) {
                        const userId = user.uid;

                        // Stocker le token dans Firestore
                        await setDoc(
                            doc(db, "users", userId),
                            {
                                fcmToken: token.value,
                                isOnline: true,
                                notificationAccepted: true,
                            },
                            { merge: true }
                        );
                    } else {
                        // console.log("Aucun utilisateur connecté.");
                    }
                });

                PushNotifications.addListener("registrationError", () => {
                    // console.error("Erreur lors de l'enregistrement :", error);
                });
            } else {
                // console.error("Permission de notification refusée sur mobile.");
            }
        } else {
            // Gestion des notifications pour le web
            if (!("Notification" in window)) {
                // console.error("Ce navigateur ne supporte pas les notifications.");
                return;
            }

            const permission = await Notification.requestPermission();

            if (permission === "granted") {
                const currentToken = await getToken(messaging, {
                    vapidKey: "BEgXoJzxbYJD9BPs9rGOcMw4520RU3jnf80hqVvlsCx1l-kWFyVbVA78hJxYnieDLN6p3p2HnuxCoFtekZVXTMQ",
                });

                if (currentToken) {
                    // console.log("Token reçu :", currentToken);

                    const auth = getAuth();
                    const user = auth.currentUser;

                    if (user) {
                        const userId = user.uid;

                        // Stocker le token dans Firestore
                        await setDoc(
                            doc(db, "users", userId),
                            {
                                fcmToken: currentToken,
                                isOnline: true,
                                notificationAccepted: true,
                            },
                            { merge: true }
                        );
                    } else {
                        // console.log("Aucun utilisateur connecté.");
                    }
                } else {
                    // console.log("Pas de token disponible.");
                }
            } else if (permission === "denied") {
                // console.error("L'utilisateur a refusé les notifications.");
            } else {
                // console.error("Les notifications sont bloquées.");
            }
        }
    } catch (error) {
        // console.error("Erreur lors de la récupération du token :", error);
    }
};

// Fonction pour supprimer le token à la déconnexion
export const removeTokenOnLogout = async () => {
    try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userId = user.uid;

            // Supprimer le token dans Firestore
            await updateDoc(doc(db, "users", userId), {
                fcmToken: deleteField(),
                isOnline: false,
            });

            // Supprimer le token localement
            await deleteToken(messaging);
            // console.log("Token supprimé avec succès.");
        }
    } catch (error) {
        // console.error("Erreur lors de la suppression du token :", error);
    }
};

// Vérification des tokens obsolètes
export const validateTokens = async () => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const userId = user.uid;
            const token = await getToken(messaging);

            // Vérifier si le token est valide
            if (!token) {
                // console.warn(`Le token pour l'utilisateur ${userId} est invalide ou expiré.`);
                await updateDoc(doc(db, "users", userId), {
                    fcmToken: deleteField(),
                });
            }
        }
    });
};

// Gestion des notifications sur mobile
if (Capacitor.isNativePlatform()) {
    PushNotifications.addListener("pushNotificationReceived", (notification) => {
        console.log("Notification reçue :", notification);
    });

    PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
        console.log("Action de notification effectuée :", action);
    });
}

export { messaging };
