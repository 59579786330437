import { createStore } from 'vuex';
import { getAuth } from 'firebase/auth';
import { collection, doc, getDoc } from 'firebase/firestore';
// import { Preferences } from '@capacitor/preferences';
import { db } from '@/main';

export default createStore({
  state: {
    showDialog: false,
    photo: null, // Photo utilisateur
  },
  mutations: {
    setShowDialog(state, value) {
      state.showDialog = value;
    },
    setPhoto(state, photoURL) {
      state.photo = photoURL; // Met à jour la photo utilisateur
    },
  },
  actions: {
    triggerDialog({ commit }) {
      commit('setShowDialog', true);
    },
    async loadUserPhoto({ commit }) {
      try {
        // Vérifiez si la photo est stockée localement
        // const { value } = await Preferences.get({ key: 'photoURL' });
        // if (value) {
        //   commit('setPhoto', value);
        //   return;
        // }

        // const localStoredPhoto = localStorage.getItem('photoURL');
        // if (localStoredPhoto) {
        //   commit('setPhoto', localStoredPhoto);
        //   return;
        // }

        // Sinon, récupérez depuis Firestore
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(collection(db, 'users'), user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData && userData.photoURL) {
              commit('setPhoto', userData.photoURL);

              // // Stockage dans Preferences et localStorage
              // await Preferences.set({ key: 'photoURL', value: userData.photoURL });
              // localStorage.setItem('photoURL', userData.photoURL);
            }
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement de la photo utilisateur :', error);
      }
    },
  },
  getters: {
    photo: (state) => state.photo, // Getter pour accéder à la photo
  },
  modules: {},
});
// import { createStore } from 'vuex';

// export default createStore({
//   state: {
//     showDialog: false
//   },
//   mutations: {
//     setShowDialog(state, value) {
//       state.showDialog = value;
//     }
//   },
//   actions: {
//     triggerDialog({ commit }) {
//       commit('setShowDialog', true);
//     }
//   },
//   modules: {}
// });
// import { createStore } from 'vuex';
// import { getAuth } from 'firebase/auth';
// import { collection, doc, getDoc } from 'firebase/firestore';
// import { Preferences } from '@capacitor/preferences';
// import { db } from '@/main';

// export default createStore({
//   state: {
//     showDialog: false,
//     photo: null, // Ajout du state pour la photo utilisateur
//   },
//   mutations: {
//     setShowDialog(state, value) {
//       state.showDialog = value;
//     },
//     setPhoto(state, photoURL) {
//       state.photo = photoURL; // Mutation pour mettre à jour la photo
//     },
//   },
//   actions: {
//     triggerDialog({ commit }) {
//       commit('setShowDialog', true);
//     },
//     async loadUserPhoto({ commit }) {
//       try {
//         // Vérifiez si la photo est stockée localement
//         const { value } = await Preferences.get({ key: 'photoURL' });
//         if (value) {
//           commit('setPhoto', value);
//           return;
//         }

//         const localStoredPhoto = localStorage.getItem('photoURL');
//         if (localStoredPhoto) {
//           commit('setPhoto', localStoredPhoto);
//           return;
//         }

//         // Sinon, récupérez depuis Firestore
//         const auth = getAuth();
//         const user = auth.currentUser;
//         if (user) {
//           const userDocRef = doc(collection(db, 'users'), user.uid);
//           const userDoc = await getDoc(userDocRef);

//           if (userDoc.exists()) {
//             const userData = userDoc.data();
//             if (userData && userData.photoURL) {
//               commit('setPhoto', userData.photoURL);

//               // Stockage dans Preferences
//               await Preferences.set({ key: 'photoURL', value: userData.photoURL });
//               localStorage.setItem('photoURL', userData.photoURL);
//             }
//           }
//         }
//       } catch (error) {
//         console.error('Erreur lors du chargement de la photo utilisateur :', error);
//       }
//     },
//   },
//   getters: {
//     photo: (state) => state.photo, // Getter pour accéder à la photo
//   },
//   modules: {},
// });
